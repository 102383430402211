<template>
  <div class="content-box">
    <main class="content-main">
      <!-- title -->
      <div class="first-level">
        <h1>BTC {{ title }}</h1>
      </div>
      <!-- card-box -->
      <div class="card-item-box">
        <section-card v-for="item in fourList" :key="item.NewsID" :data="item" :is-en-expire="isInfoEnExpire" :show-video="item > 2" :show-flag="item > 2" />
      </div>
      <!-- sub title -->
      <div class="second-level">
        <h2>All {{ title }}</h2>
        <div>
          <input v-model="listQuery.KeyWords" type="text">
          <img src="@/assets/image/btceyes/search.png" style="width: 25px; height: 25px;" @click="getNewsList(false)">
        </div>
      </div>
      <!-- card-box -->
      <div class="second-card-item-box">
        <info-card v-for="(item, index) in list" :key="index" :item="item" :is-en-expire="isInfoEnExpire" :class="{ 'bottom-line': index % 2 === 0 }" class="info-card" />
      </div>
      <!-- pagination -->
      <div class="pagination-box">
        <pagination :total="total" :page.sync="listQuery.PageNum" :size.sync="listQuery.PageSize" @pagination="getNewsList(false)"/>
      </div>
    </main>
  </div>
</template>

<script>
import InfoCard from './components/info-card'
import SectionCard from './components/section-card'
import Pagination from '@/components/pagination'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'BTCInfoMore',
  components: {
    InfoCard,
    SectionCard,
    Pagination
  },
  data () {
    return {
      list: [],
      total: 0,
      listQuery: {
        PageNum: 1,
        PageSize: 10,
        KeyWords: '',
        NewsLanguageCode: 'en'
      },
      fourList: [],
      isInfoEnExpire: false
    }
  },
  computed: {
    title () {
      return this.$route.query.type === '_exoress_en' ? 'Express' : 'Analysis'
    }
  },
  updated () {
    // 回车登录
    document.onkeyup = (e) => {
      if (e.keyCode === 13) {
        this.getNewsList(true)
      }
    }
  },
  mounted () {
    this.getNewsList(true)
    this.isInfoEnExpire = this.getUserProfile(this.$t('message._beefnewsandevents_en'))
  },
  methods: {
    getNewsList (isFirst) {
      this.listQuery.Type = this.$route.query.type
      this.$store.dispatch('GetBtcInfoNewsBySection', this.listQuery).then(() => {
        this.list = this.$store.getters.newsRows
        this.total = this.$store.getters.newsTotal
        if (isFirst) {
          this.fourList = this.list.slice(0, 4)
        }
      })
    }
  }
}
</script>

<style scoped>
.content-box {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.content-main {
  width: 1248px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 10px;
}

.content-main > .first-level {
  height: 60px;
  background-color: #ffffff;
  margin: 20px 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.content-main > .first-level > h1 {
  font-size: 30px;
  font-weight: 400;
  margin: 0;
}

.card-item-box {
  display: flex;
}

.content-main > .second-level {
  /* height: 40px; */
  background-color: #ffffff;
  margin: 20px 0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.content-main > .second-level > h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.content-main > .second-level > div {
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  padding: 3px;
}
.content-main > .second-level > div > input {
  height: 24px;
  outline: none;
  border: none;
  border-radius: 12px 0 0 12px;
  padding-left: 10px;
  background: #f5f5f5;
  font-size: 12px;
}
.content-main > .second-level > div > img {
  margin-left: 5px;
}

.info-card {
  width: 50%;
  background-color: #ffffff;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.bottom-line {
  border-bottom: 1px solid #e0e0e0;
}

.second-card-item-box {
  display: flex;
  flex-flow: wrap;
}

.pagination-box {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding-top: 20px;
}
</style>
